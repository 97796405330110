import React, { useRef, useState, useCallback } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import NavContainer from '../layouts/NavContainer';
import DefaultOverlay from '../components/Overlay';
import store from '../store';
import { IndicationAndSafety, FooterNavigation, HCPAcknowledgement, FooterContainer, FooterLegal, GeneralModal } from '../components';
import { NormalizeGlobalStyle, GlobalStyle, OneTrustOverrides } from '../components/global';
import IndicationAndSafetyDrawer from '../components/IndicationAndSafety/IndicationAndSafetyDrawer';
import ScrollLockObserver from '../components/ScrollLockObserver';
import ChatbotObserver from '../components/Chatbot';
import { Helmet } from 'react-helmet';
import favicon from '../assets/favicon.ico';
import UsableSpace from '../components/UsableSpace';
import { ga4Content } from '../util/data/ga4';
/**
 * Import ProximaNova `@font-face` declarations into our main layout.
 * Make sure to include this CSS file into any new layouts we may make.
 * This is important that this is a real CSS file rather than a
 * styled-component `createGlobalStyle()` call.
 *
 * When using the JS version, we'd see flickering of our fonts when interacting
 * with some functional component on the page (expanding sticky ISI, toggling main nav, etc.).
 * By using a real CSS file, we don't run into any of those re-rendering / flickering issues.
 */
import '../fonts/proxima-nova-font-faces.css';
import './nav-headroom.css';

// graphQL get site site metadata from gatsby-config.js
// TODO...maybe break this out into another file
const useSiteMetadata = () => {
    const { site } = useStaticQuery(
        graphql`
            query {
                site {
                    siteMetadata {
                        title
                        description
                        siteURL
                    }
                }
            }
        `
    );
    return site.siteMetadata;
};

function DefaultLayout({ children, hideStickyIsi, indicationId, jobCode, pageMeta, location, safetyDataOverride, filterIndicationDrawer, taxonomy }) {
    /**
     * Reference to the the inline indications DOM node (specifically its header)
     * so we can trigger the "hide ISI drawer" functionality based on scroll position.
     */
    let inline_indications_ref = useRef(null);
    /**
     * We are using `useOnClickOutside` for the Main Nav to close any open items
     * when we click outside. The exception for things that sit "on top" of the main nav
     * overlay is the Sticky ISI (the `IndicationAndSafetyDrawer`). The problem is that
     * clicking on this `IndicationAndSafetyDrawer` item triggers both our "click outside"
     * logic and the "open the drawer" logic. Importantly, "click outside" closes out overlay,
     * and "open the drawer" opens a different overlay. Because of this, there is a slight flicker
     * because our redux state goes from `NAV_OVERLAY_KEY` -> `false` -> `STICKY_ISI_OVERLAY_KEY`.
     *
     * To remove this flicker, we attach a ref to the ISI Drawer so we can bail early if our clickoutside
     * happens within that drawer component. This ref does exactly that.
     */
    let launch_button_container_ref = useRef(null);
    let hcp_acknowledgement_ref = useRef(null);
    let general_modal_ref = useRef(null);
    let main_nav_ref = useRef(null);
    let utility_nav_ref = useRef(null);
    let filter_wrapper_ref = useRef(null);

    const [mainNavRef, setMainNavRef] = useState(null);
    const onMainNavRef = useCallback(node => {
        // We don't reference mainNavRef, we just use this to trigger a re-render on DefaultLayout

        // ref value changed to node
        setMainNavRef(node); // e.g. change ref state to trigger re-render
        if (node === null) {
            // node is null, if DOM node of ref had been unmounted before
            console.log('node is null');
        } else {
            // ref value exists
            console.log('ref node exists');
        }
    }, []);

    // Does not render if `hideStickyIsi` is true.
    const sticky_isi = !hideStickyIsi && (
        <IndicationAndSafetyDrawer
            inline_indications_ref={inline_indications_ref}
            launch_button_container_ref={launch_button_container_ref}
            hcp_acknowledgement_ref={hcp_acknowledgement_ref}
            indicationId={filterIndicationDrawer ? indicationId : undefined}
        />
    );

    // get object metadata onject from gatsby-config.js
    const { siteURL } = useSiteMetadata();

    return (
        <>
            <Helmet
                htmlAttributes={{
                    lang: 'en',
                }}
            >
                <meta charSet="utf-8" />
                <title>{pageMeta ? pageMeta.title : ''}</title>
                <meta name="description" content={pageMeta ? pageMeta.description : ''} />
                <meta name="keywords" content={pageMeta ? pageMeta.keywords : ''} />
                <link rel="canonical" href={`${siteURL}${location ? location.pathname : ''}`} />
                <link rel="icon" href={favicon} type="image/x-icon"></link>
                { pageMeta && pageMeta.noindex && (
                    <meta name="robots" content="noindex" />
                )}
                {/* 
                    disables automatic detection of possible phone numbers | DGMKEY-696
                    https://developer.apple.com/library/archive/documentation/AppleApplications/Reference/SafariHTMLRef/Articles/MetaTags.html#//apple_ref/doc/uid/TP40008193-SW1
                */}
                <meta name="format-detection" content="telephone=no"/>
                {/* OneTrust Cookies Consent  */}
                <script
                    src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
                    type="text/javascript"
                    charset="UTF-8"
                    data-domain-script={
                        process.env.GATSBY_PRODUCTION_BUILD
                            ? 'fc3238a9-c92f-40bc-a84b-b8db8222b47f'
                            : '51b4128c-1629-4388-901a-b9f13b010cfe-test'
                    }
                ></script>
                <script type="text/javascript">{`function OptanonWrapper() { }`}</script>
                {/* OneTrust Cookies Consent end */}

                {/* dataLayer initialization for properly capturing page url on entry */}
                <script type="text/javascript">{`
                    window.dataLayer = window.dataLayer || []; 
                    window.dataLayer.push({
                        event: "initial_page_load",
                        page_url: window.location.href.replace(window.location.origin, '')
                    });
                `}</script>
                {/* end dataLayer initialization for properly capturing page url on entry */}
                
                {/* Google Tag Manager */}
                <script>{`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                })(window,document,'script','dataLayer','GTM-NMH84D3');`}</script>
                {/* End Google Tag Manager */}

                {/* GA4 Taxonomy */}
                <script type="text/javascript">
                    {`
                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push({"ga4": ${JSON.stringify(ga4Content[location.pathname])}});
                    `}
                </script>
                {/* End GA4 Taxonomy */}

                {/* Chatbot script */}
                <script
                    id="nit-alme-main"
                    type="text/javascript"
                    language="javascript"
                    src={`https://merck${!process.env.GATSBY_PRODUCTION_BUILD ? '-test' : '' }.nextit.com/alme/nextit-script-manager.js`}
                    async="true"
                ></script>
                {/* begin JSON-LD page info */}
                {/* Becuase Gatsby + Helmet are like helicopter parents, we can't just plop the 
                all the schema tags in one chunk. So we have to do it in this ugly way  */}
                {pageMeta && pageMeta.schemaJsonLD && pageMeta.schemaJsonLD.map(script => <script type="application/ld+json">{script}</script>)}                
                {/* begin JSON-LD */}
            </Helmet>
            {/* Keep global styles outside the Provider, they should never re-render */}
            <NormalizeGlobalStyle />
            <GlobalStyle />
            <OneTrustOverrides />

            {/**
             * Ensure that the Redux `Provider` is at (or near) the top of our component tree
             * so that child components can access the store.
             */}
            <Provider store={store}>
                <ScrollLockObserver />
                <ChatbotObserver inline_indications_ref={inline_indications_ref} />
                <HCPAcknowledgement hcp_acknowledgement_ref={hcp_acknowledgement_ref} />
                <UsableSpace
                    utility_nav_ref={utility_nav_ref}
                    main_nav_ref={main_nav_ref}
                    launch_button_container_ref={launch_button_container_ref}
                />
                <NavContainer
                    main_nav_ref={main_nav_ref}
                    launch_button_container_ref={launch_button_container_ref}
                    utility_nav_ref={utility_nav_ref}
                    filter_wrapper_ref={filter_wrapper_ref}
                    general_modal_ref={general_modal_ref}
                    onMainNavRef={onMainNavRef}
                />
                <main>{children}</main>
                <IndicationAndSafety inline_indications_ref={inline_indications_ref} indicationId={indicationId} safetyDataOverride={safetyDataOverride}/>
                <FooterContainer>
                    <FooterNavigation/>
                    <FooterLegal jobCode={jobCode}/>
                </FooterContainer>
                <GeneralModal general_modal_ref={general_modal_ref} />
                {sticky_isi}
                <DefaultOverlay />
            </Provider>
        </>
    );
}

DefaultLayout.propTypes = {
    hideStickyIsi: PropTypes.bool,
};

export default DefaultLayout;