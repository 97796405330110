import React, { useRef, useEffect, useCallback } from 'react';
import ReactDOMServer from 'react-dom/server';
import styled from 'styled-components';
import { Text, Heading } from '../../util/typography';
import {
    HeaderContainer as DrawerHeaderContainer,
    Header as DrawerHeader,
    HeaderText as DrawerHeaderText,
} from '../PageHeader';
import * as colors from '../../util/colors';
import { between } from 'polished';
import {
    createLocalScaleFunction,
    hasScrolledPassed,
    once,
    checkAndTriggerCancerTypeChange,
    createMarkup,
} from '../../util/functions';
import { createOverlay } from '../../components/Overlay';
import { createSelector } from '@reduxjs/toolkit';
import { useSelector, useDispatch } from 'react-redux';
import useOnClickOutside from 'use-onclickoutside';
import {
    HAS_DISMISSED_INDICATION_PREVIEW_KEY,
    HAS_SCROLLED_STORAGE_KEY,
} from '../../store/storage-keys';
import { CSSTransition } from 'react-transition-group';
import { NAVIGATION_BREAKPOINT } from '../../constants/layout';

import {
    allowScrolling,
    preventScrolling,
    openIndicationDrawerPreview,
    minimizeIndicationDrawerPreview,
    toggleIndicationDrawer,
    toggleSafetyDrawer,
    indicateScrolledPastInPageSSI,
    indicateNotScrolledPastInPageSSI,
    openOverlay,
    closeOverlay,
    closeAllMenuItems,
    closeMobileMenu,
    closeFilter,
} from '../../store/actions';
import {
    INDICATIONS_AND_SAFETY_CONTAINER,
    INDICATIONS_DRAWER_LAUNCH_BUTTON,
    SAFETY_DRAWER_LAUNCH_BUTTON,
    INDICATIONS_INFORMATION_DRAWER,
    SAFETY_INFORMATION_DRAWER,
    INDICATIONS_AND_SAFETY_DRAWER_OVERLAY,
} from '../../constants/z-index-map';
import icon_plus_small from '../../assets/icon-plus-small.svg';
import icon_close from '../../assets/icon-close.svg';
import IndicationsList from './IndicationsList';
import SafetyList from './SafetyList';
import {default as globalSafetyData} from './data/globalSafety';
import { createIndicationPreviewText } from './data';

const bp = NAVIGATION_BREAKPOINT;

const indicationPreviewData =
    'KEYTRUDA is indicated for the treatment of patients with unresectable or metastatic melanoma.<br>KEYTRUDA is indicated for the adjuvant treatment of patients with melanoma with involvement of lymph node(s) following complete resection.';

const LaunchButtonContainer = styled.div`
    display: flex;
    flex-flow: column nowrap;
    position: fixed;
    width: 100%;
    box-sizing: border-box;
    bottom: 0;
    cursor: pointer;
    max-width: 1032px;
    margin: 0 auto;
    left: 0;
    right: 0;
    padding: 0;
    pointer-events: none;
    transition: transform 0.3s ease-in-out;
    /* @todo Use real transition values for this, not just '300%' */
    transform: ${({ isCollapsed }) => (isCollapsed ? 'translateY(100%)' : 'none')};
    z-index: ${INDICATIONS_AND_SAFETY_CONTAINER};
    > * {
        pointer-events: auto;
    }

    @media (min-width: ${bp}) {
        padding: 0 20px;
    }
`;

const LaunchButton = styled.div`
    border: 1px solid ${colors.silver};
    overflow: hidden;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
    background-color: ${colors.white};
    border-radius: 8px 8px 0 0;
    box-sizing: border-box;

    @media (min-width: ${bp}) {
        box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.16);
        transition: box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out;
        &:hover {
            box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.2);
        }
    }

    header {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;

        &::after {
            content: '';
            height: 20px;
            width: 20px;
            border-radius: 50%;
            background-color: transparent;
            background-image: url(${icon_plus_small});
            background-size: 10px 10px;
            background-repeat: no-repeat;
            background-position: center center;
            border: none;
            color: transparent;
            padding: 0;
            margin: -5px -5px 0 0;
            transition: 0.3s background-color ease-in-out;
        }

        span {
            color: ${colors.blueberry};
        }
    }

    &:hover header::after {
        background-color: ${colors.mist};
    }

    > div {
        overflow: hidden;
    }
`;

const IndicationsLaunchButton = styled(LaunchButton)`
    height: ${({ isOpen }) => (isOpen ? '85px' : '44px')};
    z-index: ${INDICATIONS_DRAWER_LAUNCH_BUTTON};
    padding: ${({ isOpen }) => (isOpen ? '10px' : '7px')} 14px;
    margin-bottom: -10px;
    transition: height 0.3s ease-in-out, padding 0.3s ease-in-out;

    > div {
        display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
    }
`;

const SafetyLaunchButton = styled(LaunchButton)`
    height: 96px;
    z-index: ${SAFETY_DRAWER_LAUNCH_BUTTON};
    padding: 10px 14px;
    margin-bottom: -5px;

    @media (min-width: ${bp}) {
        margin-bottom: 0px;
    }
`;

const PreviewTitle = styled(Text.Button)`
    line-height: 20px;
    font-size: 18px;
    color: ${colors.blueberry};
`;

const PreviewText = styled(Text.Body)`
    line-height: 20px;
    color: ${colors.charcoal};

    strong {
        color: ${colors.blueberry};
        font-weight: 500;
    }
`;

const CloseButton = styled.button`
    flex: none;
    width: 44px;
    height: 44px;
    cursor: pointer;
    background-color: transparent;

    /* Any value greater than a single side */
    border-radius: 99px;
    background-image: url(${icon_close});
    background-size: 15px 15px;
    background-repeat: no-repeat;
    background-position: center center;
    background-color: ${colors.white};
    border: none;
    color: transparent;
    padding: 0;
    margin: 0;
    transition: 0.3s background-color ease-in-out;

    &:hover {
        background-color: ${colors.mist};
    }
`;

const header_bump_out_padding = 6;
const DrawerSubheaderText = styled(Heading.Subtitle)`
    /* @todo Replace with real color token */
    background: #36864a;

    display: inline-block;
    padding: ${header_bump_out_padding}px 8px;
    border-radius: 4px;
    font-weight: 500;
    color: ${colors.white};
`;

const InformationDrawer = styled.div`
    position: fixed;
    height: 100%;
    overflow: hidden;
    top: 100vh;
    left: 0;
    right: 0;
    z-index: ${({ drawerName }) =>
        drawerName === 'indications' ? INDICATIONS_INFORMATION_DRAWER : SAFETY_INFORMATION_DRAWER};
    transition: 0.4s transform ease-in-out;
    max-width: 1032px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    display: none;

    > .container {
        display: flex;
        flex-direction: column;
        background-color: ${colors.white};
        padding: ${createLocalScaleFunction(20, 320, 30, 952)}
            ${createLocalScaleFunction(20, 320, 40, 952)} 0;
        overflow: hidden;
        margin-bottom: ${({ drawerName }) => (drawerName === 'indications' ? '93px' : '0px')};
        height: 100%;
    }

    &.information-drawer-enter {
        transform: translateY(0);
        opacity: 1;
        display: flex;
    }

    &.information-drawer-enter-active,
    &.information-drawer-enter-done {
        transform: translateY(-100vh);
        opacity: 1;
        display: flex;
    }

    &.information-drawer-exit {
        transform: translateY(-100vh);
        display: flex;
    }

    &.information-drawer-exit-active,
    &.information-drawer-exit-done {
        transform: translateY(0);
        display: flex;
    }

    &.information-drawer-exit-done {
        opacity: 0;
        display: none;
    }

    /* Override existing styles */
    ${DrawerHeaderContainer} {
        position: relative;
        display: flex;
        flex: none;
        width: 100%;
        border-top-right-radius: 8px;
        border-top-left-radius: 8px;
    }

    ${DrawerHeader} {
        flex: 1;
        margin: 0;

        /*
            This seems too clever. But the math checks out! If we want to use media queries,
            we can refactor to that. I'm just wanting to scale between two values, but also calculuate
            those values based on the subheader's inner padding.
        */
        padding-left: clamp(
            ${20 - header_bump_out_padding}px,
            ${between(
                `${20 - header_bump_out_padding}px`,
                `${40 - header_bump_out_padding}px`,
                '375px',
                '1000px'
            )},
            ${40 - header_bump_out_padding}px
        );
        padding-right: ${40 - header_bump_out_padding}px;
        padding-top: clamp(20px, 4%, 30px);
        padding-bottom: clamp(20px, 4%, 30px);
    }

    ${DrawerHeaderText} {
        padding-top: 0;
        padding-bottom: 0;
        padding-left: ${header_bump_out_padding}px;

        /* Don't let the text run into the close button */
        padding-right: 35px;

        margin-bottom: 4px;
    }

    ${CloseButton} {
        position: absolute;
        top: 0;
        right: 0;

        /* 4% is between (20/320) and (30/1000), which is what we are scaling between */
        margin-top: clamp(20px, 4%, 30px);
        margin-right: clamp(20px, 4%, 30px);
    }

    @media (min-width: ${bp}) {
        padding: 0 20px;
    }
`;

export const STICKY_ISI_OVERLAY_KEY = 'STICKY_ISI';
export const StickyIsiOverlay = createOverlay(STICKY_ISI_OVERLAY_KEY, {
    extendsOverlay: StyledOverlay =>
        styled(StyledOverlay)`
            z-index: ${INDICATIONS_AND_SAFETY_DRAWER_OVERLAY};
        `,
});

const isiDrawerSelector = createSelector(
    state => state.indicationDrawerPreviewOpen,
    state => state.indicationDrawerOpen,
    state => state.safetyDrawerOpen,
    state => state.overlayActive,
    state => state.scrollLock,
    (
        indicationDrawerPreviewOpen,
        indicationDrawerOpen,
        safetyDrawerOpen,
        overlayActive,
        scrollLock
    ) => ({
        indicationDrawerPreviewOpen,
        indicationDrawerOpen,
        safetyDrawerOpen,
        overlayActive,
        scrollLock,
    })
);

const IndicationAndSafetyDrawer = ({
    inline_indications_ref,
    launch_button_container_ref,
    hcp_acknowledgement_ref,
    safetyDataOverride,
    indicationId, //if there is an indicationId here, it means we are 'filtering the drawer' 
}) => {
    const { indicationDrawerPreviewOpen, indicationDrawerOpen, safetyDrawerOpen } = useSelector(
        isiDrawerSelector
    );
    let scrolled_passed = useSelector(state => state.scrolledPastInPageSSI);
    const dispatch = useDispatch();

    useEffect(() => {
        let removeInteresctionObserver = hasScrolledPassed({
            element: inline_indications_ref && inline_indications_ref.current,
            scrolledPassedFn: () => {
                console.log('🟢 Scrolled past');
                dispatch(indicateScrolledPastInPageSSI());
            },
            stillBeneathFn: () => {
                console.log('🔴 Still beneath');
                dispatch(indicateNotScrolledPastInPageSSI());
            },
        });

        // On unmount / re-render, remove our interesction observer
        return removeInteresctionObserver;
    }, [inline_indications_ref, dispatch]);

    useEffect(() => {
        console.log(`scrolled past changed to ${scrolled_passed}`);

    }, [scrolled_passed])

    /* this was in Matt's orignal branch */

    // We really only want to run this once, even if indicationDrawerPreviewOpen or dispatch change (I think!)
    // eslint-disable-line react-hooks/exhaustive-deps
    useEffect(() => {
        if (indicationDrawerPreviewOpen && typeof window !== 'undefined') {
            return once(window, 'scroll', function () {
                // Storage value is only used when redux state is initialized (aka on page refreshes)
                window.sessionStorage.setItem(HAS_SCROLLED_STORAGE_KEY, '1');
                dispatch(minimizeIndicationDrawerPreview());
            });
        }
    }, [dispatch, indicationDrawerPreviewOpen]);

    /*end this was in Matt's orignal branch */

    useEffect(() => {
        /**
         * Scroll overflow'd indications content to top before it opens.
         * This is because if we scroll down, close, then reopen, the browser
         * will keep that scroll position.
         */
        indications_scrollable_ref.current.scrollTo(0, 0);
    }, [indicationDrawerPreviewOpen]);

    const setIndicationPreviewSessionToken = useCallback(() => {
        if (typeof window !== 'undefined' && window.origin !== 'null') {
            window.sessionStorage.setItem(HAS_DISMISSED_INDICATION_PREVIEW_KEY, '1');
        }
    }, []);

    const closeIndicationsPreview = useCallback(() => {
        //close the preview state, if it isn't already
        setIndicationPreviewSessionToken();
        dispatch(minimizeIndicationDrawerPreview());
    }, [setIndicationPreviewSessionToken, dispatch]);

    useEffect(() => {
        if (typeof window !== 'undefined' && window.origin !== 'null') {
            let has_dismissed_preview = window.sessionStorage.getItem(
                HAS_DISMISSED_INDICATION_PREVIEW_KEY
            );

            if (!has_dismissed_preview) {
                dispatch(openIndicationDrawerPreview());
                // DISABLED scroll-to-dismiss as it was being triggered by CCPA banner 'hide'
                //if the user hasn't dismissed the preview state of the indications drawer, show it and set up the scroll listener
                // return once(document, 'scroll', function () {
                //     if (!scrollLock) {
                //         closeIndicationsPreview();
                //     }

                // });
            }
        }
    }, [dispatch, closeIndicationsPreview, setIndicationPreviewSessionToken]);

    useEffect(() => {
        //since the indications preview state should only be 'on first page view', set the cookie now so it will not open again this browser session
        setIndicationPreviewSessionToken();
    }, [setIndicationPreviewSessionToken]);

    const indications_scrollable_ref = useRef(null);
    const indications_drawer_ref = useRef(null);
    const indication_launch_button_ref = useRef(null);

    const safety_scrollable_ref = useRef(null);
    const safety_drawer_ref = useRef(null);

    const openIndications = () => {
        dispatch(closeAllMenuItems());
        dispatch(closeMobileMenu());
        dispatch(openOverlay(STICKY_ISI_OVERLAY_KEY));
        dispatch(toggleIndicationDrawer());
        dispatch(closeFilter());
        dispatch(preventScrolling());
        closeIndicationsPreview();
        checkAndTriggerCancerTypeChange();
    };
    const openSafety = () => {
        // Similar to scrollable indications, scroll safety content to top
        safety_scrollable_ref.current.scrollTo(0, 0);
        dispatch(closeAllMenuItems());
        dispatch(closeMobileMenu());
        dispatch(openOverlay(STICKY_ISI_OVERLAY_KEY));
        dispatch(toggleSafetyDrawer());
        dispatch(closeFilter());
        dispatch(preventScrolling());
        checkAndTriggerCancerTypeChange();
    };

    const closeDrawers = () => {
        /**
         * @todo create `close` action creators for these
         * e.g. `dispatch(closeIndicationDrawer())` and `dispatch(closeSafetyDrawer())`
         */
        if (indicationDrawerOpen || safetyDrawerOpen) {
            if (indicationDrawerOpen) {
                dispatch(toggleIndicationDrawer());
            }
            if (safetyDrawerOpen) {
                dispatch(toggleSafetyDrawer());
            }
            dispatch(closeOverlay());
            dispatch(allowScrolling());
        }
    };

    useOnClickOutside(indication_launch_button_ref, e => {
        /**
         * If we click on the HCP Acknowledgement bail early.
         */
        if (hcp_acknowledgement_ref?.current?.contains(e.target)) {
            return;
        }

        if (indicationDrawerPreviewOpen) {
            closeIndicationsPreview();
        }
    });

    /**
     * I want to do extra checks here because otherwise, clicking on the Indications
     * drawer while it is open triggers the clickOutside logic for the Safety Drawer,
     * thus closing everything.
     */
    useOnClickOutside(indications_drawer_ref, () => {
        if (indicationDrawerOpen) {
            closeDrawers();
        }
    });
    useOnClickOutside(safety_drawer_ref, () => {
        if (safetyDrawerOpen) {
            closeDrawers();
        }
    });

    return (
        <div>
            <LaunchButtonContainer ref={launch_button_container_ref} isCollapsed={scrolled_passed}>
                <IndicationsLaunchButton
                    isOpen={indicationDrawerPreviewOpen}
                    onClick={openIndications}
                    ref={indication_launch_button_ref}
                    data-design-category="indication_vd"
                    isCollapsed={scrolled_passed}
                >
                    <header>
                        <PreviewTitle>Selected Indications</PreviewTitle>
                    </header>
                    <div>
                        <PreviewText dangerouslySetInnerHTML={createMarkup(createIndicationPreviewText(indicationId))} />
                    </div>
                </IndicationsLaunchButton>

                <CSSTransition
                    in={indicationDrawerOpen}
                    timeout={400}
                    classNames="information-drawer"
                >
                    <InformationDrawer
                        isOpen={indicationDrawerOpen}
                        ref={indications_drawer_ref}
                        drawerName="indications"
                    >
                        <DrawerHeaderContainer>
                            <DrawerHeader>
                                <DrawerHeaderText as='h2'>Selected Indications</DrawerHeaderText>
                                <DrawerSubheaderText>
                                    For KEYTRUDA® (pembrolizumab)
                                </DrawerSubheaderText>
                            </DrawerHeader>
                            <CloseButton onClick={closeDrawers}>Close</CloseButton>
                        </DrawerHeaderContainer>
                        <div className="container">
                
                            <IndicationsList
                                columns={2}
                                scrollable_ref={indications_scrollable_ref}
                                indicationId={indicationId}
                            />
                        </div>
                    </InformationDrawer>
                </CSSTransition>

                <SafetyLaunchButton
                    onClick={openSafety}
                    data-design-category="isi_ref_vd"
                    isCollapsed={scrolled_passed}
                >
                    <header>
                        <PreviewTitle>Selected Safety Information</PreviewTitle>
                    </header>
                    <div>
                        <PreviewText dangerouslySetInnerHTML={{ __html: (safetyDataOverride) ? safetyDataOverride.preview : globalSafetyData.preview }} />
                    </div>
                </SafetyLaunchButton>

                <CSSTransition in={safetyDrawerOpen} timeout={400} classNames="information-drawer">
                    <InformationDrawer
                        isOpen={safetyDrawerOpen}
                        ref={safety_drawer_ref}
                        drawerName="safety"
                    >
                        <DrawerHeaderContainer>
                            <DrawerHeader>
                                <DrawerHeaderText as='h2'>Selected Safety Information</DrawerHeaderText>
                                <DrawerSubheaderText>
                                    For KEYTRUDA® (pembrolizumab)
                                </DrawerSubheaderText>
                            </DrawerHeader>
                            <CloseButton onClick={closeDrawers}>Close</CloseButton>
                        </DrawerHeaderContainer>
                        <div className="container">
                            <SafetyList columns={2} scrollable_ref={safety_scrollable_ref} safetyDataOverride={safetyDataOverride} />
                        </div>
                    </InformationDrawer>
                </CSSTransition>
            </LaunchButtonContainer>

            <StickyIsiOverlay />
        </div>
    );
};

export default IndicationAndSafetyDrawer;
