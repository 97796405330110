import React, { useContext, useEffect, useRef } from 'react';
import styled from 'styled-components';
import logo from '../../assets/keytruda-logo.svg';
import MainMenuLarge from './MainMenuLarge';
import MainMenuSmall from './MainMenuSmall';
import CustomizeFilter from '../CustomizeFilter';
import MenuLaunchButton from './MenuLaunchButton';
import Media from 'react-media';
import Link from 'gatsby-link';
import * as colors from '../../util/colors';
import { useDispatch, useSelector } from 'react-redux';
import { useShallowEqualSelector } from '../../util/hooks';
import useKeydownHandler from '../../util/accessibility/useKeydownHandler';
import useOnClickOutside from 'use-onclickoutside';
import {
    openOverlay,
    closeOverlay,
    openMobileMenu,
    closeAllMenuItems,
    openMenuItem,
    closeMobileMenu,
    preventScrolling,
    allowScrolling,
} from '../../store/actions';
import {
    NAV_OVERLAY_KEY,
    MobileNavOverlay,
    DesktopNavInnerFilterOverlay,
} from '../../layouts/NavContainer';
import { NAVIGATION_BREAKPOINT_UNITLESS } from '../../constants/layout';
import { FilterContext } from '../FilterProvider';

const bp = NAVIGATION_BREAKPOINT_UNITLESS;

const navItemsData = [
    {
        label: 'Efficacy',
        activePath: '/efficacy/',
        url: '/efficacy/',
    },
    {
        label: 'Safety',
        activePath: '/safety/',
        children: [
            {
                label: 'Selected Adverse Reactions',
                url: '/safety/adverse-reactions/',
            },
            {
                label: 'Treatment Monitoring & Management',
                url: '/safety/monitoring-managing-adverse-reactions/',
            },
        ],
    },
    {
        label: 'Biomarker Testing',
        activePath: '/biomarker-testing/',
        children: [
            {
                label: 'Biomarker Overview',
                url: '/biomarker-testing/biomarker-overview/',
            },
            {
                label: 'PD&#8288;-&#8288;L1 Testing & Scoring',
                url: '/biomarker-testing/pd-l1/',
            },
            {
                label: 'MSI/MMR Testing',
                url: '/biomarker-testing/msi-mmr/',
            },
        ],
    },
    {
        label: 'Dosing & <br/>Preparation',
        activePath: '/dosing/',
        children: [
            {
                label: 'Dosing',
                url: '/dosing/options/',
            },
            {
                label: 'Preparation, Storage & Administration',
                url: '/dosing/preparation-storage-administration/',
            },
            {
                label: 'Dose Modifications',
                url: '/dosing/dose-modifications/',
            },
        ],
    },
    {
        label: 'Resources',
        activePath: '/resources/',
        children: [
            {
                label: 'HCP & Patient Resources',
                url: '/resources/hcp-and-patient-resources/',
            },
            {
                label: 'Patient Support Program',
                url: '/resources/key-you-patient-support-program/',
            },
            {
                label: 'Mechanism of Action',
                url: '/resources/mechanism-of-action/',
            },
            {
                label: 'Surgeon Resource Center',
                url: '/resources/surgeon-resources/',
            },
        ],
    },

];

// These are shared locally, and only matter for local components. No need to share outside this component.
const nav_bottom_border_size = '2px';
const inner_flex_nav_z_index = 3;
const StyledNav = styled.nav`
    width: 100%;
    background: ${colors.white};
    position: sticky;
    border-bottom: ${nav_bottom_border_size} solid ${colors.silver};
    transition: border-color 0.3s ease-in-out;
    z-index: 0;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.15);

    ${({ isOpen }) => isOpen && `border-color: ${colors.keytrudaGreen};`}

    @media (hover:hover) {
        &:hover {
            border-color: ${colors.keytrudaGreen};
        }
    }

    > .inner-flex {
        display: ${props => (props.filterOpen ? 'none' : 'flex')};
        justify-content: space-between;
        box-sizing: border-box;
        padding: 0 0 0 20px;
        max-width: 1032px;
        margin: 0 auto;

        position: relative;
        background-color: ${colors.white};
        z-index: ${inner_flex_nav_z_index};
    }

    //handling for border when the filter is open
    ${props =>
        props.filterOpen && `border-bottom: ${nav_bottom_border_size} solid ${colors.silver};`}
`;

const LogoContainer = styled(Link)`
    padding: 0 20px 0 0;
    flex: 2 1 auto;
    display: grid;
    align-items: center;
    > img {
        width: 100%;
        max-width: 160px;
        display: block;
    }
`;

// Position this _outside_ our StyledNav
const CustomizeFilterOutOfDocumentFlow = styled.div`
    position: absolute;
    top: calc(100% + ${nav_bottom_border_size});
    width: 100%;
    z-index: ${inner_flex_nav_z_index - 1};
    display: flex;
    flex-flow: column nowrap;
    overflow: visible;
    //handle drop shadow to avoid it 'overlapping' with Main Nav (above it)
    &:after {
        content: '';
        position: absolute;
        width: 100%;
        bottom: 1px;
        z-index: -1;
        height: 15px;
    }

    @media (max-width: ${bp}px) {
        top: calc(100% + ${props => (props.filterOpen ? '0px' : nav_bottom_border_size)});
    }
`;

const mainNavSelector = state => state.mainNavigation;

const MainNavigation = ({
    main_nav_ref,
    onMainNavRef,
    launch_button_container_ref,
    filter_wrapper_ref,
    general_modal_ref,
}) => {
    // @see https://react-redux.js.org/api/hooks#equality-comparisons-and-updates
    const { openItem, mobileMenuOpen } = useShallowEqualSelector(mainNavSelector);
    const filterOpen = useSelector(state => state.filterOpen);
    const dispatch = useDispatch();

    // Determine whether to render CustomizeFilter by checking if this page has been wrapped with FilterProvider
    const hasFilterContext = useContext(FilterContext) != null;

    const styled_nav_ref = useRef(null);

    /**
     * @param {Number} index The index of the menu item.
     */
    const itemClickHandler = index => {
        if (openItem === index) {
            // Clicked an open menu, close it
            dispatch(closeAllMenuItems(null));
            dispatch(closeOverlay());
            dispatch(allowScrolling());
        } else {
            // Otherwise open the new menu (which will close the currently open one)
            dispatch(openMenuItem(index));
            dispatch(openOverlay(NAV_OVERLAY_KEY));
            dispatch(preventScrolling());
        }
    };

    const mobileMenuLaunchHandler = () => {
        dispatch(openMobileMenu());
        dispatch(openOverlay(NAV_OVERLAY_KEY));
        dispatch(preventScrolling());
    };

    const closeAllHandler = () => {
        dispatch(closeAllMenuItems());
        dispatch(closeMobileMenu());
        dispatch(closeOverlay());
        dispatch(allowScrolling());
    };

    // Close everything when we keydown 'Esc'
    useKeydownHandler(closeAllHandler, 'Escape');

    // Close everything when we click outside the main nav
    useOnClickOutside(styled_nav_ref, e => {
        
        /**
         * If we click on the Sticky ISI launch button, bail early.
         */
        if (launch_button_container_ref?.current?.contains(e.target)) {
            return;
        }

        /**
         * Only close everything if something is open.
         * Only works for desktop. To get this working on Mobile, we'd need 2 `useOnClickOutside` calls.
         * Aka, don't fire `closeAllHandler` _every_ time we click outside.
         */
        if (openItem != null && !mobileMenuOpen) {
            closeAllHandler();
        }
    });

    useEffect(() => {
        if(openItem !== null) {
            // console.log('Debugging: ', 'Inicializando', openItem);
            console.log('Debugging: ', 'Submenu', openItem);
        }
    }, [openItem]);



    return (
        <>
            <StyledNav isOpen={openItem !== null} filterOpen={filterOpen} ref={main_nav_ref}>
                <div className="inner-flex" ref={onMainNavRef}>
                    <LogoContainer
                        title="Home"
                        to="/"
                        onClick={closeAllHandler}
                        data-design-category="top_nav_vd"
                    >
                        <img src={logo} alt="KEYTRUDA® (pembrolizumab): Website for Health Care Professionals" />
                    </LogoContainer>

                    {/*  Mobile-only */}
                    <MenuLaunchButton clickHandler={mobileMenuLaunchHandler} />

                    {/* for SEO scanability, the MainMenuLarge is always in the DOM */}
                    <MainMenuLarge
                        styled_nav_ref={styled_nav_ref}
                        clickHandler={itemClickHandler}
                        items={navItemsData}
                        openItem={openItem}
                        closeAllHandler={closeAllHandler}
                    />
                </div>
                <CustomizeFilterOutOfDocumentFlow filterOpen={filterOpen}>
                    <DesktopNavInnerFilterOverlay />
                    {hasFilterContext &&
                        <CustomizeFilter
                            filter_wrapper_ref={filter_wrapper_ref}
                        />
                    }
                </CustomizeFilterOutOfDocumentFlow>
            </StyledNav>

            {/*
                Importantly this overlay lives between the <StyledNav /> and
                the <MainMenuSmall /> so that the overlay can sit _above_ the
                <StyledNav /> while also sitting _below_ the <MainMenuSmall />
            */}
            <MobileNavOverlay />

            <Media query={{ maxWidth: bp }}>
                {matches => {
                    if (!matches) {
                        if (mobileMenuOpen) {
                            /**
                             * If we had the mobile menu open, and resized to desktop,
                             * then close those mobile menu.
                             *
                             * We can maybe be more surgical here, but the nav seems buggy
                             * if a subnav is open and you resize larger: you'd think the desktop
                             * nav would render open, but it kind of half renders? Not sure where
                             * the bug is, but this is such an edge case its OK to just close everything.
                             */
                            closeAllHandler();
                        }
                        return null;
                    }

                    // Otherwise, our media query matches, render the mobile menu
                    return (
                        <MainMenuSmall
                            closeAllHandler={closeAllHandler}
                            isOpen={mobileMenuOpen}
                            itemClickHandler={itemClickHandler}
                            items={navItemsData}
                            openItem={openItem}
                            general_modal_ref={general_modal_ref}
                        />
                    );
                }}
            </Media>
        </>
    );
};

export default MainNavigation;
