import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import * as colors from '../../util/colors';
import { Text } from '../../util/typography';
import { LinkText } from './CrossLinkSection';
import { defaultRailCardItemData } from './data';
import Link from 'gatsby-link';
import { createMarkup } from '../../util/functions';

const Box = styled(Link).attrs(props => ({
    'data-design-category': props.dataDesignCategory || props['data-design-category'] || 'content_link_vd'
}))`
    background-color: white;
    border-radius: 10px;
    padding: 20px;
    text-decoration: none;
    display: block;
    transition: box-shadow 0.3s ease-in-out;
    border: 1px solid ${colors.pebble};
    &:hover {
        border-color: ${colors.white};
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.16);

        ${Text.Body} {
            color: ${colors.blueberryDark}
        }
    }
`;

const SubHeading = styled(Text.Eyebrow)`
    display: block;
    margin-bottom: 8px;
`;

const Card = ({ text, url, eyebrow }) => {
    return (
        <Box to={url}>
            <SubHeading>{eyebrow}</SubHeading>
            <LinkText dangerouslySetInnerHTML={createMarkup(text)} />
        </Box>
    );
};

Card.propTypes = {
    text: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    eyebrow: PropTypes.string.isRequired,
};

Card.defaultProps = {
    ...defaultRailCardItemData,
};

export default Card;
