import React from 'react';
import styled from 'styled-components';
import Preferred from '../../assets/nccn-preferred.svg';
import { WYSIWYG, Heading, NotationBlock, Footnote } from '../../util/typography';
import ComponentWrapper from '../layout/ComponentWrapper';
import { createMarkup } from '../../util/functions';

// const TitleWrapper = styled.div`
//     > p:first-child {
//         line-height: 2.4rem;
//     }
// `;

const ContentWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    --multiplier: calc(640px - 100%);
    margin-bottom: 20px;
`;

const InlineImage = styled.div`
    min-width: 150px;
    flex: 1 1 0;
    flex-basis: calc(var(--multiplier) * 999);

    & img {
        width: 130px;
        margin-bottom: 20px;
    }
`;

const InlineContent = styled.div`
    flex: 1 1 calc(100% - 160px);

    > *:last-child {
        margin-bottom: 0;
    }
`;

// const Title = styled(Heading.H2)`
//     margin-bottom: 30px;
// `;

const Subtitle = styled(Heading.H4)`
    margin-bottom: 10px;
`;

const NCCNReferences = styled.div`
    margin-top: 1em;
`;

const NCCNcallout = props => {
    let data = props.data;

    return (
        <>
            <ComponentWrapper>
                {/* <TitleWrapper>
                        <Title theme={data.theme}>{data.title}</Title>
                    </TitleWrapper> */}
                <ContentWrapper>
                    {data.showBadge && (
                        <InlineImage>
                            <img alt={data.alt} src={Preferred} />
                        </InlineImage>
                    )}
                    <InlineContent>
                        <Subtitle theme={data.theme} dangerouslySetInnerHTML={createMarkup(data.title)}/>
                        {data.bodyCopy && 
                            <WYSIWYG theme={data.theme} dangerouslySetInnerHTML={createMarkup(data.bodyCopy)} />
                        }
                    </InlineContent>
                </ContentWrapper>
             
                {(props.footnotes || props.definitions) && <NotationBlock footnotes={props.footnotes} definitions={props.definitions} />}
                {/* references are ONLY included in the display of NCCN, for regulatory/compliance reasons. All other references should be manually entered in the page-wide ReferencesBlock at the bottom of a page */}
                {props.references && 
                    <NCCNReferences>
                        {props.references.map((item, index) => {
                            return <Footnote key={index} label={item.label} text={item.text} theme={props.theme} />;
                        })}
                    </NCCNReferences>
                }
            </ComponentWrapper>
        </>
    );
};

export default NCCNcallout;
