import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import CrossLinkSection from './CrossLinkSection';
import { SIDEBAR_BREAKPOINT } from '../../constants/layout';


// Moving padding to parent breaks "component query"
const Container = styled.div`
    overflow: hidden;

    > *:first-child {
        margin-top: 0;
        padding-top: 0;
    }

    > *:last-child {
        margin-bottom: 0;
        padding-bottom: 0;
    }
`;

const InterestLinksContainer = styled.div`
    display: none;
    
    @media (min-width: ${SIDEBAR_BREAKPOINT}) {
        display: block;
    }
`;

const CrossLink = ({ sections, interestLinks }) => {
    return (
        <Container>
            {sections.map((section, i) => (
                <CrossLinkSection
                    heading={section.heading}
                    items={section.items}
                    showAsCard={false}
                    key={i}
                />
            ))}
            {interestLinks && 
                <InterestLinksContainer>
                    <CrossLinkSection
                        heading={interestLinks.heading}
                        items={interestLinks.items}
                        showAsCard={true}
                    />
                </InterestLinksContainer>
            }
        </Container>
    );
};

CrossLink.prototypes = {
    content: PropTypes.arrayOf({
            heading: PropTypes.string,
            items: PropTypes.arrayOf(
                PropTypes.shape({
                    text: PropTypes.string.isRequired,
                    url: PropTypes.string.isRequired,
                    // this is needed for the card
                    eyebrow: PropTypes.string,
                })
            ),
            dataType: PropTypes.oneOf(['efficacy', 'related', 'intereseted']),
            showAsCard: PropTypes.bool
        }
    ),
};

export default CrossLink;
